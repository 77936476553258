export const calculateEndpoint = (
  lat: number,
  lng: number,
  distance: number,
  direction: 'left' | 'right',
  angle: number
) => {
  const earthRadius = 6378.1; // Radius of the Earth in km
  const directionFactor = direction === 'right' ? 1 : -1;
  const brng = angle * directionFactor * (Math.PI / 180); // Convert angle to radians with direction

  const latRad = lat * (Math.PI / 180); // Convert latitude to radians
  const lngRad = lng * (Math.PI / 180); // Convert longitude to radians

  const endLat = Math.asin(
    Math.sin(latRad) * Math.cos(distance / earthRadius) +
      Math.cos(latRad) * Math.sin(distance / earthRadius) * Math.cos(brng)
  );
  const endLng =
    lngRad +
    Math.atan2(
      Math.sin(brng) * Math.sin(distance / earthRadius) * Math.cos(latRad),
      Math.cos(distance / earthRadius) - Math.sin(latRad) * Math.sin(endLat)
    );

  return { lat: endLat * (180 / Math.PI), lng: endLng * (180 / Math.PI) }; // Convert back to degrees
};
export enum zIndexes {
  TILELAYER = 10,
  ORTHOPHOTO = 20,
  INTERPOLATION = 30,
  SCAN_PATH = 40,
  OTHER_UTILITIES = 50,
  UTILITY = 60,
  TRANSMITTER_LIST = 70,
  DEPTH_LIST = 80,
  PIPE_CROSSINGS = 90,
  Y_PEAKS = 100,
  ZOOMHANDLER = 110
}
