import type { Scan } from '@graphql/generated';
import { type Option, SELECT_ALL } from './MultiSelect';

export const getMethodOptions = (scans: Scan[]): Option[] => {
  const methodSet = new Set<string>();

  scans.forEach((scan) => {
    if (scan.scan_frequencies?.items) {
      scan.scan_frequencies.items.forEach((frequency) => {
        if (frequency?.transmitters?.items) {
          frequency.transmitters.items.forEach((transmitter) => {
            if (transmitter?.method) {
              methodSet.add(transmitter.method);
            }
          });
        }
      });
    }
  });

  const methodOptions: Option[] = Array.from(methodSet).map((method) => ({
    label: method,
    value: method
  }));

  methodOptions.unshift({ label: SELECT_ALL, value: SELECT_ALL });

  return methodOptions;
};
