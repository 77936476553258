import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container } from './styles';
import Header from './Header';
import Input from '@pages/Main/DetailsView/Feedback/CommentsPopUp/Input';
import { useCreateCommentMutation } from '@graphql/generated';
import UserContext from '@contexts/UserContext';
import { useUrlParams } from '@hooks/useUrlParams';
import { CommentType } from './Comments/Comment';
import Comments from './Comments';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import PresetComments, { PresetCommentInterface } from './PresetComments';
import { presetComments } from '@constants/presetComments';
import { set } from 'date-fns';

interface Props {
  onClosePopUp?: () => void;
  setAddedCommentsLength: React.Dispatch<React.SetStateAction<number>>;
  addedCommentsLength: number;
  comments: CommentType[];
  setComments: React.Dispatch<React.SetStateAction<CommentType[]>>;
}

export const CommentsPopUp: React.FC<Props> = (props) => {
  const { haveComments, setHaveComments } = useContext(AnalyzedEntityContext);
  const { onClosePopUp, setAddedCommentsLength, addedCommentsLength, comments, setComments } =
    props;
  const [text, setText] = useState('');
  const [createComment, { loading }] = useCreateCommentMutation();
  const { user } = useContext(UserContext);
  const { getUrlParam } = useUrlParams();
  const [currentPresetComment, setCurrentPresetComment] = useState<PresetCommentInterface>(
    presetComments as unknown as PresetCommentInterface
  );
  const currentId = getUrlParam('currentId') ?? '';
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const onSend = (): void => {
    if (!currentId) {
      return;
    }

    createComment({
      variables: {
        createCommentsInput: {
          text,
          changed_by: user?.account?.username || '',
          analyzedEntityCommentsId: currentId
        }
      }
    })
      .then(() => {
        setText('');
        const comment = {
          text,
          changed_by: user?.account?.username,
          analyzedEntityCommentsId: currentId,
          createdAt: new Date().toISOString()
        };
        if (comments.length === 0) {
          setHaveComments(haveComments + 1);
        }
        const tempComments = [...comments, comment];
        setComments(tempComments as CommentType[]);
        setAddedCommentsLength(addedCommentsLength + 1);
        if (onClosePopUp) {
          onClosePopUp();
        }
      })
      .catch((e) => console.error('Error while creating comment', e));
    setCurrentPresetComment(presetComments as unknown as PresetCommentInterface);
  };

  const onClickPresetComment = (presetComment: PresetCommentInterface): void => {
    if (presetComment.next) {
      setText(text + ' ' + presetComment.text.slice(0, -1));
      setCurrentPresetComment(presetComment.next as unknown as PresetCommentInterface);
    } else {
      setText(text + ' ' + presetComment.text + '\n');
      setCurrentPresetComment(presetComments as unknown as PresetCommentInterface);
    }
  };
  const onClickComment = (comment: CommentType): void => {
    setText(comment.text ?? '');
  };
  useEffect(() => {
    textAreaRef.current?.dispatchEvent(new Event('input'));
  }, [text]);

  return (
    <Container>
      <Header onClosePopUp={onClosePopUp} />
      <Comments comments={comments} onClickComment={onClickComment} />
      <PresetComments
        disabled={comments.length > 0 && !text.trim()}
        onClickPresetComment={onClickPresetComment}
        currentPresetComment={currentPresetComment as unknown as PresetCommentInterface[]}
      />
      <Input
        onSend={onSend}
        text={text}
        disabled={comments.length > 0 && !text.trim()}
        setText={setText}
        sendDisabled={loading || !text.trim()}
        textAreaRef={textAreaRef}
      />
    </Container>
  );
};

export default CommentsPopUp;
