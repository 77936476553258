import React, { useEffect, useRef } from 'react';
import { Container } from './styles';
import Comment, { CommentType } from './Comment';

interface Props {
  comments?: CommentType[];
  onClickComment: (comment: CommentType) => void;
}

const Comments: React.FC<Props> = (props) => {
  const { comments, onClickComment } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = containerRef.current;
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }, [comments?.length]);
  return (
    <Container ref={containerRef}>
      {comments && comments.length > 0 && (
        <Comment comment={comments[comments.length - 1]} onClickComment={onClickComment}></Comment>
      )}
    </Container>
  );
};

export default Comments;
