import { useState } from 'react';
import { Site, useSearchAllSitesInfoLazyQuery } from '@graphql/generated';

import { reportAllPagesResults } from '@helpers/reportAllPagesResults';

type Result = {
  readonly sites: Site[];
  readonly getSites: () => Promise<void>;
  readonly loading: boolean;
};

export const useAllSitesQuery = (): Result => {
  const [sites, setSites] = useState<Site[]>([]);
  const [getData, data] = useSearchAllSitesInfoLazyQuery();
  const [loading, setLoading] = useState(false);

  const getDataByFilters = async (): Promise<void> => {
    setSites([]);
    setLoading(true);
    await reportAllPagesResults(
      getData,
      (res) => {
        const filteredItems = res?.data?.searchSites?.items?.filter((item) => item?.id) || [];
        setSites((sites) => [...sites, ...((filteredItems ?? []) as Site[])]);
      },
      (res) => res.data?.searchSites?.nextToken
    ).finally(() => {
      setLoading(false);
    });
  };

  return {
    sites,
    getSites: getDataByFilters,
    loading
  };
};
